<template lang="html">
  <section class="accesos-productos">
    <h1 class="waypoints title">{{ $t('home.intro.nuestros_productos') }}</h1>
    <div class="container row-start">
      <article class="acceso-producto  waypoints" effect="animate__fadeInUp"
        v-for="(option, key) in $t('categorias_productos')" :key='key'
        :style="`background-image:url(/img/productos/${$t(`categorias_productos['${key}']`).id}/banner-acceso.jpg)`">
        <router-link class="router-link-exact-active router-link-active"
          :to="'/' + $t('paths.ficha-categoria-producto') + '/' + $t('paths.'+ option.id +'')">
          {{ option.name }}
        </router-link>
        <div class="texto">
          <p v-html="option.descripcion"></p>
        </div>
        <router-link class="enlace-cover" :to="'/' + $t('paths.ficha-categoria-producto') + '/' +  $t('paths.' + option.id + '')"></router-link>
      </article>

      <!-- <article class="acceso-producto -caldos waypoints" effect="animate__fadeInUp">
        <router-link to="/">{{$t('home.intro.nuestros_productos.caldos.titulo')}}</router-link>
        <div class="texto">
          <p v-html="$t('home.intro.nuestros_productos.caldos.cuerpo')"></p>
        </div>
      </article>

      <article class="acceso-producto -soluciones waypoints" effect="animate__fadeInUp">
        <router-link to="/">{{$t('home.intro.nuestros_productos.soluciones.titulo')}}</router-link>
        <div class="texto">
          <p v-html="$t('home.intro.nuestros_productos.soluciones.cuerpo')"></p>
        </div>
      </article> -->
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'acceso-productos'
}
</script>

<style scoped lang="scss">
.acceso-productos {}
</style>