<template>
    <footer class="footer">
        <img class="logo-ian" src="/img/logo-ian.svg" alt="Logo IAN">
        <div class="container ">
            <div class="top row-start">
                <div class="column" v-for="(item, key) in $t('footer')" :key="key">
                    <h2>{{ item.title.toUpperCase() }}</h2>
                    <template v-for="(sub_item, idx) in item.sub_groups">
                        <router-link v-if="sub_item.to" :to="'/' + $t(`paths.${sub_item.to}`)" :key="idx">{{ sub_item.title
                        }}</router-link>
                        <router-link v-if="sub_item.to_params" :to="sub_item.to_params" :key="idx">{{ sub_item.title
                        }}</router-link>
                        <a v-if="sub_item.href" target="_blank" :href="sub_item.href" :key="idx">{{ sub_item.title }}
                            <i v-if="sub_item.icon" :class="sub_item.icon"></i>
                        </a>
                    </template>
                </div>
                <div class="logos">
                    <a v-for="(logo, key) in $t('footer.footer.logos')" :key="key" :target="logo.target" :href="logo.href">
                        <img :src="logo['img-path']" :alt="logo['img-alt']" />
                        <p v-if="logo.text" v-html="logo.text" />
                    </a>
                </div>
            </div>
            <div class="bottom row-between">
                <nav class="row-start">
                    <template v-for="(item, key) in $t('sub_footer')">
                        <router-link v-if="item.to" :to="'/' + $t(`paths.${item.to}`)" :key="key">{{ item.title
                        }}</router-link>
                        <a v-if="item.href" :href="item.href" :key="key" target="_blank">{{ item.title }}</a>
                    </template>
                </nav>
                <div>
                    <span :class="current_language == oLang ? 'lang' : 'lang-selected'" v-for="(oLang, key) in languages"
                        :key="key">
                        <router-link v-if="$route.params.product"
                            :to="{ name: 'productos', params: { lang: oLang, id: ' ' } }">
                            {{ oLang }}
                        </router-link>
                        <router-link v-else :to="{ name: $route.name, params: { lang: oLang, id: ' ' } }">
                            {{ oLang }}
                        </router-link>
                    </span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import messages from '@/translations';

export default {
    name: 'app-footer',
    mounted() {
        this.current_language = this.$route.params.lang
    },
    data: () => ({
        current_language: "es",
        languages: Object.keys(messages),
    }),
    watch: {
        '$route'(route) {
            this.current_language = route.params.lang
        }
    }
}
</script>