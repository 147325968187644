import Vue from "vue";
import Vuex from "vuex";
import store from "./index.js";

Vue.use(Vuex);
import axios from "axios";
const NEWS_PAGE = 12;
function checkForNewPage(object, page, search, category) {
  if (
    object.lastFilters.search == search &&
    object.lastFilters.category == category
  ) {
    if (
      (object.items[page] != "undefined" || object.items[page] != null) &&
      Object.keys(object.items[page]).length > 0
    ) {
      return null;
    }
  } else {
    return -1;
  }
  return page;
}
const contents = {
  state: {
    categories: [],
    entriesRsc: {
      viewPage: 1,
      lastFilters: {
        search: null,
        category: null,
      },
      items: {},
      maxItems: null,
    },
    loading: false,
  },
  getters: {
    getCategories: (state) => {
      return state.categories;
    },
    getEntriesRsc: (state) => (page) => {
      return state.entriesRsc.items[page];
    },
    getLoading: (state) => {
      return state.loading;
    },
  },
  actions: {
    requestEntries(
      { state, commit },
      { page = 1, filter, length = NEWS_PAGE }
    ) {
      let search = filter.search ? filter.search : "";
      let category = filter.category;
      page = checkForNewPage(state.entriesRsc, page, search, category);
      let lang = filter.lang;
      var langStoreId = store.getters.getLanguage == "es" ? 1 : 0;
      if (page == -1 || langStoreId != filter.lang) {
        page = 1;
        commit("cleanEntriesRsc");
      } else if (page === null) {
        return;
      }
      commit("loading");
      let filtersSelect = [
        {
          name: "name",
          search: search,
          textFilter: 1,
        },
        {
          name: "category.id",
          search: category,
        },
        {
          name: "visible",
          search: 1,
        },
        {
          name: "lang",
          search: lang,
        },
      ];

      let data = {
        page: page,
        length: length,
        columns: [
          {
            name: "category",
            onlyWith: true,
          },
        ],
        filters: filtersSelect,
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };

      return new Promise((resolve) => {
        axios
          .post("/api/entries", data)
          .then((response) => {
            commit("addEntriesRsc", {
              entriesRsc: response.data.data,
              page: page,
              maxItems: response.data.recordsFiltered,
              filters: {
                search: search,
                category: category,
              },
            });
            commit("endLoad");
            resolve(response.data.data);
          })
          .catch((error) => {
            commit("null", error);
            resolve(false);
          });
      });
    },

    requestCategories({ commit, state }, { filter }) {
      // if (state.categories != null && state.categories.length > 0) {
      //   return;
      // }
      let lang = filter.lang;
      let data = {
        filters: [
          {
            name: "lang",
            search: lang,
          },
        ],
      };
      return new Promise((resolve) => {
        axios
          .post("/api/entries/categories", data)
          .then((response) => {
            state.categories = response.data.data;
            resolve(response.data);
          })
          .catch((error) => {
            commit("null", error);
            resolve(false);
          });
      });
    },

    requestEntrie({ commit }, slug) {
      return new Promise((resolve) => {
        axios
          .get("/api/entries/" + slug + "?c-load[]=category")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            commit("null", error);
            resolve(false);
          });
      });
    },

    requestProyectos({ commit }, lang) {
      let data = {
        filters: [
          {
            name: "lang",
            search: lang,
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      return new Promise((resolve) => {
        axios
          .post("/api/project", data)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            commit("null", error);
            resolve(false);
          });
      });
    },

    sendContact({ commit }, data) {
      return new Promise((resolve) => {
        axios
          .post("/api/autocontact/contacto", data)
          .then((response) => {
            if (response.data.status) {
              resolve(true);
              commit("setAlert", 1009);
            } else {
              resolve(false);
            }
            resolve(response.data.status);
          })
          .catch((error) => {
            commit("null", error);
            resolve(false);
          });
      });
    },
  },
  mutations: {
    loading(state) {
      state.loading = true;
    },
    endLoad(state) {
      state.loading = false;
    },
    addEntriesRsc(state, { entriesRsc, page, maxItems, filters }) {
      entriesRsc.forEach((element) => {
        if (state.entriesRsc.items[page] == null) {
          state.entriesRsc.items[page] = {};
        }
        state.entriesRsc.items[page][element.slug] = element;
      });
      state.entriesRsc.maxItems = maxItems;
      state.entriesRsc.lastFilters = filters;
      let todelete = page - 3;
      if (todelete > 1) {
        state.entriesRsc.items[todelete] = {};
      }
    },
    cleanEntriesRsc(state) {
      state.entriesRsc.viewPage = 1;
      state.entriesRsc.items = {};
      state.entriesRsc.lastFilters = {
        theme: null,
        zone: null,
      };
    },
  },
};

export default contents;
