<template>
  <div>
    <header class="app-header" :min="min">
      <div class="top">
        <div class="container row-end">
          <a target="_blank" href="https://www.linkedin.com/company/carretilla/" class="social fa fa-linkedin"></a>
          <span :class="current_language == oLang ? 'lang' : 'lang-selected'" v-for="(oLang, key) in languages"
            :key="key">
            <router-link v-if="$route.params.product" :to="{ name: 'productos', params: { lang: oLang, id: ' ' } }">
              {{ oLang }}
            </router-link>
            <router-link v-else :to="{ name: $route.name, params: { lang: oLang, id: ' ' } }">
              {{ oLang }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="bottom">
        <div class="container row-between">
          <router-link to="/">
            <img src="/img/logo-ian.svg" class="logo-ian" alt="IAN">
          </router-link>
          <button class="showmenu" @click="show_menu = true">
            <span></span><span></span><span></span>
          </button>
        </div>
      </div>
    </header>
    <div class="menu" :show="show_menu">
      <a @click="show_menu = false" class="close">✕</a>
      <nav>
        <div class="group" v-for="(group, key) in $t('navbar_menu')" :key="key">
          <a v-if="group.sub_groups" class="main" @click="showmenu">{{ group.title }}</a>
          <div v-if="group.sub_groups" class="subgroup">
            <template v-for="(sub_group, idx) in group.sub_groups">
              <router-link v-if="sub_group.to" :to="'/' + $t(`paths.${sub_group.to}`)" :key="idx">{{ sub_group.title
              }}</router-link>
              <router-link v-if="sub_group.to_params" :to="sub_group.to_params" :key="idx">{{ sub_group.title
              }}</router-link>
              <a v-if="sub_group.href" target="_blank" :href="sub_group.href" :key="idx">{{ sub_group.title
              }}</a>
            </template>
          </div>
          <router-link v-if="!group.sub_groups && group.to" :to="'/' + $t(`paths.${group.to}`)">{{ group.title
          }}</router-link>
          <a v-if="!group.sub_groups && group.href" target="_blank" :href="group.href">{{ group.title }}</a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import messages from '@/translations';

export default {
  name: 'app-header',
  mounted() {
    this.current_language = this.$route.params.lang
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 400) {
        this.min = true;
      } else {
        this.min = false;
      }
    });

  },
  data: () => ({
    show_menu: false,
    min: (window.scrollY >= 400),
    languages: Object.keys(messages),
    current_language: "es"
  }),
  methods: {
    showmenu(event) {

      if (event.target.classList.contains('show')) {
        event.target.nextElementSibling.classList.remove('show')
        event.target.classList.remove('show')
      } else {
        event.target.nextElementSibling.classList.add('show')
        event.target.classList.add('show')
      }


    }
  },
  watch: {
    '$route'(route) {
      this.show_menu = false;
      this.current_language = route.params.lang
    }
  }
}
</script>