export default {
  custom: {
    obligatorio: "El campo es obligatorio",
    email: {
      required: "El campo email es obligatorio",
      email: "Debes indicar una dirección de correo electrónico válida",
    },
    telefono: {
      required: "El campo teléfono es obligatorio",
      length: "El campo teléfono está mal formado",
    },
    nombre: {
      required: "El campo nombre es obligatorio",
    },
    mensaje: {
      required: "El campo mensaje es obligatorio",
    },
    medioComunicacion: {
      required: "El campo medio de comunicación es obligatorio",
    },
    asunto: {
      required: "El campo asunto es obligatorio",
    },
    apellidos: {
      required: "El campo apellidos es obligatorio",
    },
    politica: {
      required: "Debes aceptar la política de de protección de datos",
    },
    direccion: {
      required: "El campo direccion es obligatorio",
    },
    pais: {
      required: "El campo pais es obligatorio",
    },
    carta_presentacion: {
      required: "El campo carta de presentación es obligatorio",
    },
    publicidad: {
      required: "El campo carta de presentación es obligatorio",
    },
    localidad: {
      required: "El campo localidad es obligatorio",
    },
    cp: {
      required: "El campo código postal es obligatorio",
    },
    provincia: {
      required: "El campo provincia es obligatorio",
    },
    doc_adjunto: {
      size: "El tamaño maximo son 2MB",
      mimes: "El formato debe ser PDF",
    },
  },
};