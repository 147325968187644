<template>
    <section class="proyectos-home-banner waypoints" effect="animate__fadeInUp">
        <div class="container">
            <div class="container-texto">
                <router-link class="logo" :to="$t('paths.compromiso-ian')">
                    <img :src="$t('home.proyects_home.img')" alt="Comprometidos para un futuro mejor">
                </router-link>
                <div v-html="$t('home.proyects_home.text')"></div>
                <router-link :to="$t('paths.compromiso-ian')">
                    <img src="/img/flecha-next.svg" alt="Ir al enlace">
                </router-link>
            </div>
            <div class="container-slider" v-if="entriesEs !== null && $route.params.lang === 'es'">
                <slick ref="es" :options="slickOptions">
                    <template v-for="(entry, key, index) in entriesEs">
                        <div class="slide" v-if="index < 3" :key="entry.slug">
                            <div class="proyecto-el-slide">
                                <img :src="entry.image.url" :alt="entry.name">
                                <div class="descripcion">
                                    <p class="title">
                                        {{ entry.name }}
                                    </p>
                                    <p>
                                        {{ entry.header }}
                                    </p>
                                    <router-link :to="$t('paths.proyectos-rsc-ficha') + '/' + entry.slug">
                                        {{ $t('projects_rsc.result_projects.show_project') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </slick>
            </div>
            <div class="container-slider" v-if="entriesEn !== null && $route.params.lang === 'en'">
                <slick ref="en" :options="slickOptions">
                    <template v-for="(entry, key, index) in entriesEn">
                        <div class="slide" v-if="index < 3" :key="entry.slug">
                            <div class="proyecto-el-slide">
                                <img :src="entry.image.url" :alt="entry.name">
                                <div class="descripcion">
                                    <p class="title">
                                        {{ entry.name }}
                                    </p>
                                    <p>
                                        {{ entry.header }}
                                    </p>
                                    <router-link :to="$t('paths.proyectos-rsc-ficha') + '/' + entry.slug">
                                        {{ $t('projects_rsc.result_projects.show_project') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </slick>
            </div>
        </div>
    </section>
</template>

<script>
import Slick from 'vue-slick';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'proyectos-home',
    data() {
        return {
            slickOptions: {
                slidesToShow: 1,
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: 6000,
            },
            entriesEn: null,
            entriesEs: null,
        };
    },
    created() {
        this.getEntries({
            filter: {
                search: '',
                category: '',
                lang: parseInt(this.$t(`langs.${this.$route.params.lang}`))
            }
        })
    },
    computed: {
        ...mapGetters({
            rscEntries: 'getEntriesRsc'
        }),
    },
    methods: {
        ...mapActions(['requestEntries']),
        getEntries(filters) {
            this.requestEntries(filters).then(() => {
                filters.filter.lang === 0 ? this.entriesEs = this.rscEntries(1) : this.entriesEn = this.rscEntries(1)
            })
        }
    },
    components: {
        Slick
    },
    watch: {
        "$route.params.lang": function () {
            this.entriesEn = null
            this.entriesEs = null
            this.getEntries({
                filter: {
                    search: '',
                    category: '',
                    lang: parseInt(this.$t(`langs.${this.$route.params.lang}`))
                }
            })
        }
    }
}
</script>