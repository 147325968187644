import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/translations';

let lang = navigator.language || navigator.userLanguage;
let part = (location.pathname.split("/").length > 0) ? location.pathname.split("/")[1] : "";
if (part.length == 2) {
    lang = part;
}
if (messages[lang] == null) {
    lang = 'es';
}

Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: lang,
    messages,
});

export function setI18nLanguage(locale) {
    i18n.locale = locale
}