import { render, staticRenderFns } from "./acceso-productos.vue?vue&type=template&id=0c4a0d9d&scoped=true&lang=html"
import script from "./acceso-productos.vue?vue&type=script&lang=js"
export * from "./acceso-productos.vue?vue&type=script&lang=js"
import style0 from "./acceso-productos.vue?vue&type=style&index=0&id=0c4a0d9d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c4a0d9d",
  null
  
)

export default component.exports