import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/i18n";
import languageSelector from "@/components/language-selector.vue";
import Home from "../views/Home.vue";
import messages from "@/translations";
import store from "@/store/index";

Vue.use(VueRouter);

let routes = [
  {
    path: "/:lang(es|en)?",
    name: "language-selector",
    component: languageSelector,
    children: [
      {
        name: "home",
        component: Home,
      },
      {
        name: "nuestra-historia",
        component: () => import("@/views/nuestra-historia.vue"),
      },
      {
        name: "proyectos-ayudas",
        component: () => import("@/views/proyectos-ayudas.vue"),
      },
      {
        name: "proyectos-rsc",
        component: () => import("@/views/proyectos-rsc.vue"),
      },
      {
        name: "proyectos-rsc-ficha",
        component: () => import("@/views/proyecto-rsc.vue"),
        meta: {
          idParameter: true,
        },
      },
      {
        name: "quienes-somos",
        component: () => import("@/views/quienes-somos.vue"),
      },
      {
        name: "innovacion",
        component: () => import("@/views/innovacion.vue"),
      },
      {
        name: "calidad–y-seguridad-alimentaria",
        component: () => import("@/views/calidad-e-innovacion.vue"),
      },
      {
        name: "mision-vision",
        component: () => import("@/views/mision-vision.vue"),
      },
      {
        name: "nuestras-marcas",
        component: () => import("@/views/nuestras-marcas.vue"),
      },
      {
        name: "empresas-grupo",
        component: () => import("@/views/empresas-grupo.vue"),
        meta: {
          idParameter: true,
        },
      },
      {
        name: "empresas-grupo-norenense",
        component: () => import("@/views/empresas-grupo.vue"),
      },
      {
        name: "exportacion",
        component: () => import("@/views/exportacion.vue"),
      },
      {
        name: "certificaciones",
        component: () => import("@/views/certificaciones.vue"),
        meta: {
          idParameter: true,
        },
      },
      {
        name: "sala-de-prensa",
        component: () => import("@/views/sala-de-prensa.vue"),
      },
      {
        name: "contacto",
        component: () => import("@/views/contacto.vue"),
      },
      {
        name: "compromiso-ian",
        component: () => import("@/views/compromiso-ian.vue"),
      },
      {
        name: "productos",
        component: () => import("@/views/productos.vue"),
      },
      {
        name: "ficha-categoria-producto",
        component: () => import("@/views/ficha-categoria-producto.vue"),
        meta: {
          idParameter: true,
        },
        props: true,
      },
      {
        name: "ficha-producto",
        component: () => import("@/views/ficha-producto.vue"),
        meta: {
          idParameter: true,
          productIdParameter: true,
        },
        props: true,
      },
      {
        name: "avisolegal",
        component: () => import("@/views/aviso-legal.vue"),
      },
      {
        name: "politica_privacidad",
        component: () => import("@/views/politica-privacidad.vue"),
      },
      {
        name: "politica_cookies",
        component: () => import("@/views/politica-cookies.vue"),
      },
      {
        name: "mapa_web",
        component: () => import("@/views/mapa-web.vue"),
      },
      {
        name: "formaciondeacogida",
        component: () => import("@/views/formaciondeacogida.vue"),
      },
    ],
  },
];

routes[0].children.forEach(function(value) {
  if (value.path == null) {
    value.path = i18n.t("paths." + value.name);
    value.alias = [];
    value.meta = value.meta ? value.meta : {};
    value.meta.seo = value.meta.seo ? value.meta.seo : {};
    Object.keys(messages).forEach(function(lang) {
      if (value.meta.seo[lang] == null) {
        value.meta.seo[lang] = messages[lang].seo[value.name];
      }
      if (
        messages[lang].paths[value.name] != null &&
        messages[lang].paths[value.name] != value.path &&
        value.alias.includes(messages[lang].paths[value.name]) == false
      ) {
        value.alias.push(messages[lang].paths[value.name]);
      }
    });
    if (value.meta.idParameter) {
      value.path += "/:id?";
      value.alias.forEach(function(alia, index) {
        value.alias[index] += "/:id?";
      });
    }
    if (value.meta.productIdParameter) {
      value.path += "/:product?";
      value.alias.forEach(function(alia, index) {
        value.alias[index] += "/:product?";
      });
    }
  }
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.params.lang == null) {
    to.params.lang = store.getters.getLanguage
      ? store.getters.getLanguage
      : i18n.locale;
    next({
      path: to.params.lang + "/" + i18n.t("paths." + to.name),
      name: to.name ? to.name : "home",
      params: to.params,
    });
    return;
  }

  if (to.params.lang && to.params.lang != store.getters.getLanguage) {
    let lang = to.params.lang;
    store.dispatch("changeLanguage", lang);
  }

  let explode = to.path.split("/");

  if (to.name && to.name != "home" && explode.length > 2) {
    explode.shift();
    explode.shift();
    if (to.meta.idParameter && to.params.id && to.params.id != " ") {
      explode.pop();
    }
    if (to.meta.productIdParameter && to.params.product) {
      explode.pop();
    }
    explode = explode.join("/");
    if (explode != i18n.t("paths." + to.name)) {
      let url = "/" + to.params.lang + "/" + i18n.t("paths." + to.name);
      if (to.params.id && to.params.id != " ") {
        url += "/" + to.params.id;
      }
      if (to.params.product) {
        url += "/" + to.params.product;
      }
      next(url);
      return;
    }
  }

  let meta = to.meta.seo ? to.meta.seo[to.params.lang] : null;

  if (!!meta && !!meta.description) {
    document.title = meta.title;
    document.head.querySelector("[name=title]").content = meta.title;
    document.head.querySelector("[name=description]").content =
      meta.description;
  } else {
     document.title = i18n.t("seo.default.title");
     document.head.querySelector("[name=title]").content = i18n.t(
       "seo.default.title"
     );
     document.head.querySelector("[name=description]").content = i18n.t(
       "seo.default.subtitle"
     );
  }
  next();
});

export default router;
