export default {
  custom: {
    obligatorio: "The field is mandatory",
    email: {
      required: "The email is mandatory",
      email: "You must provide a valid email address",
    },
    telefono: {
      required: "The telephone field is required",
      length: "The telephone field is wrong",
    },
    nombre: {
      required: "The name field is required",
    },
    mensaje: {
      required: "The message field is required",
    },
    apellidos: {
      required: "The surname field is required",
    },
    politica: {
      required: "You must accept the privacy policy",
    },
    comunicaciones: {
      required: "You must accept to send communications",
    },
    empresa: {
      required: "Company field is required",
    },
    direccion: {
      required: "The address field is required",
    },
    pais: {
      required: "Country field is required",
    },
    carta_presentacion: {
      required: "The letter of introduction field is required",
    },
    doc_adjunto: {
      size: "El tamaño maximo son 2MB",
      mimes: "El formato debe ser PDF",
    },
  },
};