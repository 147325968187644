<template>
  <div id="app">
    <app-header></app-header>
    <router-view />
    <app-footer></app-footer>
  </div>
</template>


<script>

  import appheader from '@/components/app-header'
  import appfooter from '@/components/app-footer'
  import 'slick-carousel/slick/slick.css';

  require('waypoints/lib/noframework.waypoints.js')

  export default {
    name: 'app',
    mounted() {
      this.waypoints();
    },
    components: {
      'app-header': appheader,
      'app-footer': appfooter
    },
    methods: {

      waypoints: function () {

        /* eslint-disable */

        var waypointsinstant = document.querySelectorAll('.waypoints-instant');

        waypointsinstant.forEach(e => {
          e.classList.add('animated fadeInUp')
        })

        var waypoints = document.getElementsByClassName('waypoints');

        for (var i = 0; i < waypoints.length; i++) {

          new Waypoint({
            element: waypoints[i],
            offset: '85%',
            handler: function () {
              var element = this.key.split('-')[1];
              var effect = this.element.attributes.getNamedItem('effect');
              (effect != undefined) ? effect = effect.value : effect = "animate__fadeInUp";
              this.element.classList.add("animate__animated", effect)
            }
          });

        }

        waypoints = document.getElementsByClassName('animation');

        for (i = 0; i < waypoints.length; i++) {

          new Waypoint({
            element: waypoints[i],
            offset: '80%',
            handler: function () {
              element = this.key.split('-')[1];
              this.element.classList.add("animation_on")
            }
          });

        }

      }

    },
    watch: {
      '$route': function () {
        
        setTimeout(() => {
          this.waypoints();
        }, 100);
        
      }
    },
  }
</script>

<style lang="scss">
  @import '@/assets/css/colors.scss';
  @import '@/assets/css/style.scss';
  @import '@/assets/css/animaciones.scss';
  @import '@/assets/css/responsive.scss';
  @import '@/assets/css/font-awesome.scss';
  @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
</style>