import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from "@/i18n";

Vue.config.productionTip = false

/*AXIOS*/
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL = store.state.server;
Vue.use(VueAxios, axios)
/*AXIOS*/

/*Validator Base*/
import validatorEs from "@/validator/es";
import validatorEn from "@/validator/en";
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
extend("email", {
  ...email,
  message:
    i18n.locale === "es"
      ? validatorEs.custom.email.email
      : validatorEn.custom.email.email,
});

extend("required", {
  ...required,
  message:
    i18n.locale === "es"
      ? validatorEs.custom.obligatorio
      : validatorEn.custom.obligatorio,
});


import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
